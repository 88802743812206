var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e92cb5e8ebadc136473a338aa636d2177c4a7619"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";


Sentry.init({
  dsn: "https://74970f546e04204042e62fdd0cb54157@o4505909542191104.ingest.sentry.io/4505920458326016",
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay()],
  ignoreErrors: [
    /Network Error/i,
    /Request Failed/i,
    /Load failed/i,
    /failed to load/i,
    /failed to fetch/i,
  ],
});
